import '../sentry.client.config';
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import dynamic from 'next/dynamic';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  ApiError,
  Policy,
  AppNameProvider,
  Forbidden,
  ErrorFallback,
  Layout,
} from 'ui';
import { getVersion } from '../utils/version';
import useUser from '../auth/useUser';
import { APP_NAME } from '../appName';
import { ErrorBoundary } from 'react-error-boundary';

// We load this component dynamically to disable SSR on it because it depends on the value of the window object.
const AuthContextProvider = dynamic(
  () => {
    return import('../auth/AuthContext');
  },
  { ssr: false }
);

const errorFunction = (error: unknown) => {
  if (error instanceof ApiError) {
    console.error(error, {
      errorCode: error.errorCode,
      appName: error.appName,
      ctx: error.ctx,
      cause: error.cause,
      requestId: error.requestId,
      errorId: error.errorId,
    });
    toast.error(
      `Error: ${error.message}\nCódigo: ${error.errorCode}\n[${error.errorId}]`,
      {
        duration: 5000,
      }
    );
    return;
  }

  let realError = error;
  if (!(error instanceof Error)) {
    realError = new Error(JSON.stringify(error));
  }

  console.error(realError);
  toast.error(`Error: ${(realError as Error).message}`);
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: errorFunction,
  }),
  mutationCache: new MutationCache({
    onError: errorFunction,
  }),
});

type ExtendedAppProps = AppProps & {
  Component: AppProps['Component'] & { skipAuth?: boolean };
};

function ComponentWrapper({ Component, pageProps }: ExtendedAppProps) {
  const user = useUser();

  if (Component.skipAuth) {
    return (
      <React.StrictMode>
        <Component {...pageProps} />
      </React.StrictMode>
    );
  }

  // The AuthContextProvider (and the keycloak-js library) does not play well with StrictMode's unmounting and remounting of the components.
  // Running AuthContextProvider inside StrictMode causes an infinite redirect loop when coming back from the idP.
  return (
    <AuthContextProvider clientId="monorepo">
      <Policy
        user={user}
        roles={['login']}
        fallback={
          <Forbidden
            text={'No tienes permiso para acceder a la aplicación'}
            userLogout={user?.actions?.logout}
            email={user?.email}
          />
        }
      >
        <React.StrictMode>
          <Layout
            pageTitle="Links únicos"
            userName={user?.name}
            noPageTitleHeader={true}
            customMainStyle={{
              minHeight: `calc(100vh - ${60}px - ${60}px)`,
              minWidth: '410px',
              backgroundColor: '#fff',
            }}
            customHeaderStyle={{
              background: 'linear-gradient(to right, #115ab7, #16a5d1)',
            }}
            asideMenuOptions={[]}
            userLogout={user?.actions?.logout}
          >
            <Component {...pageProps} />
          </Layout>
        </React.StrictMode>
      </Policy>
    </AuthContextProvider>
  );
}

function MyApp(props: ExtendedAppProps) {
  console.log('Version', getVersion());

  return (
    <ErrorBoundary FallbackComponent={() => <ErrorFallback />}>
      <AppNameProvider appName={APP_NAME}>
        <RecoilRoot>
          <Toaster />
          <QueryClientProvider client={queryClient}>
            <ComponentWrapper {...props} />
          </QueryClientProvider>
        </RecoilRoot>
      </AppNameProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
